import { forwardRef, RefObject, useEffect, useImperativeHandle, useRef, useState } from "react";
import { getMaps } from "../data/maps";
import { Entity, getEntities } from "../model/entities";

interface MapImageProps {
   stage: number;
   map: number;
   data?: (Entity|undefined)[][];
   source?: string;
}

const bgWidth = 512;
const bgHeight = 896;
const bgArea = {
   left: 38,
   top: 73,
   right: 481,
   bottom: 828
};

const cellWidth = 184 / 2;
const cellHeight = 144 / 2;
const cellGap = 0;

const rows = 8;
const columns = 5;

const realAreaWidth = cellWidth * columns + cellGap * (columns - 1);
const realAreaHeight = cellHeight * rows + cellGap * (rows - 1);
const scaleBgWidth = realAreaWidth / (bgArea.right - bgArea.left);
const scaleBgHeight = realAreaHeight / (bgArea.bottom - bgArea.top);

const scaledWidth = Math.round(bgWidth * scaleBgWidth);
const scaledHeight = Math.round(bgHeight * scaleBgHeight);
const scaledArea = {
   left: Math.round(bgArea.left * scaleBgWidth),
   top: Math.round(bgArea.top * scaleBgHeight),
   right: Math.round(bgArea.right * scaleBgWidth),
   bottom: Math.round(bgArea.bottom * scaleBgHeight)
}

type ImageRef = {
   name: string;
   ref: RefObject<HTMLImageElement>;
   loaded: boolean;
}

const MapImage = forwardRef<HTMLCanvasElement, MapImageProps>((props, ref) => {

   var canvasRef = useRef<HTMLCanvasElement>(null);
   var [ loaded, setLoaded ] = useState(false);

   useImperativeHandle(ref, () => canvasRef.current!, [canvasRef]);

   const imageRefs : ImageRef[] = [ "map" , "globe" , "feather" , "hammer" , "book" , "entrance" , "exit" , "monster" , "elite" , "boss" , "boulder", "shortBackground", "slate" ].map(name => ({
      name: name,
      ref: useRef<HTMLImageElement>(null),
      loaded: false
   }));

   function onImageLoaded(imageRef: ImageRef) {
      imageRef.loaded = true;
      if (imageRefs.every(imageRef => imageRef.loaded)) {
         setLoaded(true);
      }
   }

   function drawImage(imageRefs: ImageRef[], context: CanvasRenderingContext2D, name: string, x: number, y: number, width: number, height: number) {
      const ref = imageRefs.find(ref => ref.name === name);
      if (ref) {
         const elem = ref.ref.current;
         if (elem) {
            context.drawImage(elem, x, y, width, height);
         }
      }
   }


   var instance = props.data ?? getMaps(props.stage)[props.map];
   var counts = instance.reduce((acc, row) => row.reduce((acc, cell) => { if (cell) { acc[cell] = (acc[cell]||0) + 1; } return acc; }, acc), {} as Partial<Record<Entity, number>>);
   var unknowns = instance.reduce((acc, row) => row.reduce((acc, cell) => { if (!cell) { acc++; } return acc; }, acc), 0);
   var missing = getEntities().map(([name, entity]) => {
      if (entity.count) {
         const expected = entity.count(props.stage);
         const actual = counts[name]||0;
         if (expected != actual) {
            return `${expected == 1 && actual == 0 ? "the" : expected - actual} ${name}${expected - actual > 1 ? "s" : ""}`;
         }
      }
   }).filter(_ => _);
   var unknownsString = unknowns > 0 && missing.length < 5 ? `In the ${unknowns} unknowns, help find ${missing.join(" & ")} and tell Whack` : undefined;

   useEffect(() => {
      if (loaded) {
         var canvas = canvasRef.current;
         if (canvas) {
        
            var context = canvas.getContext("2d")!;
            if (context) {
               drawImage(imageRefs, context, "shortBackground", 0, 0, scaledWidth, scaledHeight);

               const boxLeft = scaledArea.right - 125;
               const boxTop = scaledArea.bottom  + 35;
               const boxLeftMargin = 15;

               context.font = "16pt Arial";
               context.fillStyle = "beige";
               context.fillText(`Stage ${props.stage}`, 340, 35);
               context.fillText(`Map ${props.map + 1}`, 440, 35);
            
               instance.forEach((row, y) => row.forEach((entity, x) => {
                  if (entity === undefined) {
                        context.font = "40pt Arial";
                        context.fillStyle = "beige";
                        context.fillText("?", scaledArea.left + x * (cellWidth + cellGap) + cellWidth / 2 - 15, scaledArea.top + y * (cellHeight + cellGap) + cellHeight / 2 + 20);
                  } else if (entity === "empty") {
                     context.globalAlpha = 0.6;
                     drawImage(imageRefs, context, "slate", scaledArea.left + x * (cellWidth + cellGap), scaledArea.top + y * (cellHeight + cellGap), cellWidth, cellHeight);
                     context.globalAlpha = 1;
                 } else {
                     drawImage(imageRefs, context, entity, scaledArea.left + x * (cellWidth + cellGap), scaledArea.top + y * (cellHeight + cellGap), cellWidth, cellHeight);
                  }
               }));

               if (unknownsString) {
                  context.font = "10pt Arial"
                  context.fillStyle = "beige"
                  context.fillText(unknownsString, 20, scaledHeight - 15);
               }
               else {
                  context.font = "10pt Consolas, monospace"
                  context.fillStyle = "beige"
                  context.fillText(props.source ?? "https://lavacave.training", scaledWidth - 210, scaledHeight - 20);
               }
            }
         }
      }
   }, [canvasRef, imageRefs, loaded]);

   function onClick() {
      if (!props.data) {
         document.location = `/img/maps/lavacave-${props.stage}-${props.map + 1}.png`;
      }
   };

   const width = scaledWidth;
   const height = scaledHeight;
   return <>
      <canvas ref={canvasRef} width={width} height={height} onClick={onClick}/>
      {
         imageRefs.map((imageRef) => <img key={imageRef.name} ref={imageRef.ref} src={require(`./img/${imageRef.name}.png`)} style={{ display: 'none' }} onLoad={() => onImageLoaded(imageRef)}/>)
      }
   </>
});

   
export default MapImage;
